import React, { useState } from 'react';
import { Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';
import { URL_API } from 'gatsby-env-variables';

const ForgotPasswordPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        login_bg: file(relativePath: { eq: "account-bg.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `
  );

  const [submitStatus, setSubmitStatus] = useState('standby');
  const [formStatus, setFormStatus] = useState('standby');
  const [formError, setFormError] = useState('');

  const [formData, setFormData] = useState({
    email: '',
  });

  const bg_login = getImage(data.login_bg);
  console.log(bg_login);
  // Use like this: bg-accounts-bg bg-cover bg-center
  const loginBg = convertToBgImage(bg_login);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  async function formSubmit(e) {
    e.preventDefault();
    setSubmitStatus('loading');
    const response = await fetch(`${URL_API}/validate-email`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'omit',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
        'Access-Control-Allow-Headers':
          'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
      },
      redirect: 'follow',
      referrer: 'client',
      body: JSON.stringify(formData),
    });

    const json = await response.json();
    if (json.status == 'Success') {
      setFormStatus('success');
      console.log(json);
      setSubmitStatus('standby');
    } else {
      setFormStatus('error');
      console.log(json);
      if (json.errors.email) {
        setFormError(json.errors.email[0]);
      } else if (json.errors.password) {
        setFormError(json.errors.password[0]);
      } else {
        setFormError(json.errors[0]);
      }
      setSubmitStatus('standby');
    }
  }

  return (
    <>
      <BackgroundImage
        Tag="section"
        className="bg-center"
        // Spread bgImage into BackgroundImage:
        {...loginBg}>
        <div className="grid grid-cols-12 xl:pt-40 pt-28 xl:px-0 px-10 h-screen w-screen text-white" id="login-form">
          <div className="xl:col-span-4 xl:col-start-5 xl:col-end-9 col-span-12">
            <h1 className="xl:text-5xl text-3xl font-bold pb-5 font-rokkit">Forgot password</h1>
            <form>
              {formStatus == 'error' ? (
                <div className="xl:col-span-4 col-span-12">
                  <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">{formError}</strong>
                  </div>
                </div>
              ) : null}

              {formStatus == 'success' ? (
                <div className="xl:col-span-4 col-span-12">
                  <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">Email sent successfully!</strong>
                  </div>
                </div>
              ) : null}

              <div className="xl:col-span-4 col-span-12 pt-5 mb-5">
                <p>Email</p>
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  className="rounded-md border-orange-150 border w-full h-12 px-5 mb-3 focus:outline-none focus:shadow-none pb-1 bg-transparent"
                  placeholder="Enter email"
                />
              </div>

              <div className="xl:col-span-4 col-span-12">
                {submitStatus == 'standby' ? (
                  <button
                    type="submit"
                    onClick={formSubmit}
                    id="login-btn"
                    className="uppercase button-white font-opensans rounded-md text-xl  h-14 w-full font-bold mt-5 transition-all duration-500">
                    Send
                  </button>
                ) : (
                  <button
                    type="submit"
                    id="login-btn"
                    className="uppercase button-white font-opensans rounded-md text-xl  h-14 w-full font-bold mt-5 transition-all duration-500">
                    Sending...
                  </button>
                )}
              </div>
            </form>
            <p className="pt-4 text-center text-white">
              Have an Account?{' '}
              <Link to="/login" className="underline">
                Log In
              </Link>
            </p>
          </div>
        </div>
      </BackgroundImage>
    </>
  );
};

export default ForgotPasswordPage;
